import { createContext, useState, useEffect, useCallback, useRef } from 'react'
import { fetchOrCreateCheckout, replaceLineItems } from './services/checkoutService'

const mapCartLineItems = item => ({
  variantId: item.variant.id,
  quantity: item.quantity,
  customAttributes: item.customAttributes?.map((a) => ({
    key: a.key,
    value: a.value
  }))
})

const sortLineItemsByIds = (lineItems, ids) => {
  let sortedLineItems = []
  for (let id of ids) {
    const found = lineItems.find(lineItem => lineItem.variant.id === id)
    if (found) {
      sortedLineItems = [...sortedLineItems, found]
    }
  }

  return sortedLineItems
}

const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

export const StoreContext = createContext({})
const { Provider } = StoreContext

function StoreProvider(props) {
  const [products, setProducts] = useState([])
  const [cart, setCart] = useState(undefined)
  const [isProcessing, setIsProcessing] = useState(false)
  const [cartModifierProcessing, setCartModifierProcessing] = useState({})
  let abortController = useRef(null)

  useEffect(() => {
    abortController.current = new AbortController()
  }, [])

  useEffect(() => {
    readCart()
    window.addEventListener("focus", readCart)
    return () => window.removeEventListener("focus", readCart)
  }, [])

  const finalCart = cart === undefined
    ? undefined
    : cart?.lineItems?.length > 0
    ? { ...cart, isProcessing }
    : null

  const readCart = useCallback(() => {
    fetchOrCreateCheckout(false).then(async (initialCart) => {
      if(initialCart === null) {
        setCart(null);
        return
      }
      const newLineItems = initialCart.lineItems.map(lineItem => {
        let quantity = lineItem.quantity
        if(!lineItem.variant.quantityAvailable) {
          quantity = 0
        } else if(lineItem.quantity > lineItem.variant.quantityAvailable) {
          quantity = lineItem.variant.quantityAvailable
        }
        return ({
          ...lineItem,
          quantity
        })
      }).filter(item => item.quantity !== 0)

      const newCart = await reloadCart(newLineItems)

      setCart(newCart)
    })
  }, [])

  const reloadCart = useCallback(async (currentlineItems) => {
    if (abortController.current && isProcessing) {
      abortController.current.abort()
    }
    abortController.current = new AbortController()
    setIsProcessing(true)

    const newCart = await replaceLineItems(currentlineItems.map(mapCartLineItems).filter(l => !l.variant), abortController.current.signal)

    if (!newCart) {
      return
    }
    const ids = currentlineItems.map(lineItem => lineItem.variant.id)
    const sortedLineItems = sortLineItemsByIds(newCart.lineItems, ids)
    const sortedCart = { ...newCart, lineItems: sortedLineItems }

    setIsProcessing(false)

    return sortedCart
  }, [isProcessing, cartModifierProcessing])

  const add = useCallback(async (productVariant, customAttributes = [], quantity = 1, cartModifierId) => {

    let isPartiallyUpdated = false

    function checkQuantityAfterAdd(lineItems) {
      return lineItems.map(lineItem => {
        let quantity = lineItem.quantity

        if(!lineItem.variant.quantityAvailable) {
          console.log("This variant is not available in stock, can't add this product to cart")
          isPartiallyUpdated = true
          quantity = 0
        } else if(lineItem.quantity > lineItem.variant.quantityAvailable) {
          isPartiallyUpdated = true
          quantity = lineItem.variant.quantityAvailable
        }

        return ({
          ...lineItem,
          quantity
        })
      }).filter(item => item.quantity !== 0)
    }

    // fallback
    const oldCart = cart ? {...cart} : undefined
    try {
      setIsProcessing(true)
      setCartModifierProcessing({
        ...cartModifierProcessing,
        [cartModifierId]: true
      })
      // Optimistic cart update
      let lineItems = cart ? [...cart.lineItems] : [];
      const foundItem = lineItems.find(lineItem => lineItem.variant.sku === productVariant.sku);


      let requestedQuantity = 1

      if (foundItem) {
        lineItems = lineItems.map(lineItem => {
          let newQuantity = lineItem.quantity

          if(lineItem.variant.sku === productVariant.sku) {
            newQuantity = lineItem.quantity + quantity
          }

          requestedQuantity = newQuantity
          return ({
            ...lineItem,
            quantity: newQuantity
          })
      })
      } else {
        lineItems = [{
          variant: {
            ...productVariant,
            product: products.find(p => p.id === productVariant.productId)
          },
          customAttributes: customAttributes,
          id: productVariant.id,
          quantity: 1,
        }, ...lineItems]
      }

      lineItems = checkQuantityAfterAdd(lineItems)

      if(cart) {
        setCart({
          ...cart,
          lineItems
        })
      } else {
        setCart({
          lineItems,
          subtotalPrice: {
            amount: "0.0",
            currencyCode: null
          },
          totalPrice: {
            amount: "0.0",
            currencyCode: null
          },
          shippingPrice: null, // nullable! if shippingMethod was not picked in current session, then it's null
          currencyCode: null,
          checkoutUrl: null // url to Shopify checkout
        })
      }
      // First add
      await fetchOrCreateCheckout(true)

      // Confirm and reload on Shopify
      let newCart = await reloadCart(lineItems)

      if(newCart.lineItems.some(lineItem => lineItem.quantity > lineItem.variant.quantityAvailable)) {
        const newLineItems = checkQuantityAfterAdd(newCart.lineItems)
        newCart = await reloadCart(newLineItems)
      }

      setCart(newCart)
      const itemsToReturn = newCart ? newCart.lineItems : lineItems

      const variantToReturn = itemsToReturn.find(item => item.variant.id === productVariant.id)
      setCartModifierProcessing({})
      return {
        variant: variantToReturn?.variant ?? null,
        quantity: variantToReturn?.quantity ?? 0,
        cart: newCart,
        isPartiallyUpdated,
        requestedQuantity
      }
    } catch(err) {
      console.log(err)
      setCart(oldCart)
      setIsProcessing(false)
      setCartModifierProcessing({})
    }
  }, [cart, products])

  const remove = useCallback(async (productVariant, cartModifierId) => {
    // Fallback to old cart
    const oldCart = {...cart}
    try {
      // Optimistic cart update
      setCartModifierProcessing({
        ...cartModifierProcessing,
        [cartModifierId]: true
      })
      const localLineItems = cart.lineItems.filter(item => item.variant.id !== productVariant.id)
      setCart({
        ...cart,
        lineItems: localLineItems
      })

      // Confirm and reload on Shopify
      const newCart = await reloadCart(localLineItems)
      setCart(newCart)

      setCartModifierProcessing({})
      return {
        cart: newCart,
        variant: productVariant,
      }
    } catch(err) {
      console.log(err)
      setCart(oldCart)
      setIsProcessing(false)
      setCartModifierProcessing({})
    }
  }, [cart])

  const replace = useCallback(async (lineItems, cartModifierId) => {
    let lineItemsPartiallyUpdated = []
    let isPartiallyUpdated = false

    function checkQuantityAfterReplace(lineItems) {
      return lineItems.map(lineItem => {
        let quantity = lineItem.quantity

        const variant = {
          ...lineItem.variant,
          product:
            lineItem.variant.product ||
            products.find(p => p.id === lineItem.variant.productId) ||
            oldCart.lineItems.find(item =>
              item.variant.id === lineItem.variant.id || item.variant.productId === lineItem.variant.productId
            )?.product
        }

        if(lineItem.quantity > lineItem.variant.quantityAvailable) {
          isPartiallyUpdated = true
          quantity = lineItem.variant.quantityAvailable

          lineItemsPartiallyUpdated.push({
            variant,
            quantity,
            requestedQuantity: lineItem.quantity
          })
        }
        return ({
          ...lineItem,
          variant,
          quantity
        })
      }).filter(item => item.quantity !== 0)
    }

    // Fallback to old cart
    const oldCart = {...cart}
    try {
      setCartModifierProcessing({
        ...cartModifierProcessing,
        [cartModifierId]: true
      })

      // Optimistic cart update
      let newLineItems = lineItems.map(item => ({
        ...item,
        id: item.variant.id
      })).reduce((lineItems, item) => {
        let newItem = {...item}
        if(lineItems.some(lineItem => lineItem.variant.id === item.variant.id)) {
          return lineItems.map((lineItem) => ({
            ...lineItem,
            quantity: lineItem.quantity + item.quantity
          }))
        }
        return [...lineItems, newItem]
      }, [])

      newLineItems = checkQuantityAfterReplace(newLineItems)

      setCart({
        ...cart,
        lineItems: newLineItems
      })

      // Confirm and reload on Shopify
      let newCart = await reloadCart(newLineItems)

      if(newCart.lineItems.some(lineItem => lineItem.quantity > lineItem.variant.quantityAvailable)) {
        const newLineItems = checkQuantityAfterReplace(newCart.lineItems)
        newCart = await reloadCart(newLineItems)
      }
      setCart(newCart)
      setCartModifierProcessing({})

      return {
        cart: newCart,
        isPartiallyUpdated,
        lineItemsPartiallyUpdated
      }
    } catch (err) {
      console.log(err)
      setCart(oldCart)
      setIsProcessing(false)
      setCartModifierProcessing({})
    }
  }, [cart, products])

  return <Provider {...props} value={{
    cart: finalCart,
    setProducts,
    cartModifierProcessing,
    add,
    remove,
    replace
  }} />
}

export default StoreProvider
